/*
 * @Date: 2023-04-10 12:58:17
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 19:09:43
 * @FilePath: \P1-M1-QRP-CODE\pages\m1-005.js
 */
import React, {useEffect} from 'react';
import Header from '@/style10/HeaderForm'; // 头部 组件
import Central from '@/style10/Central'; // 表单 组件
import Banner from '@/style10/Banner';
import RegisterForm from '@/style10/RegisterForm';
import Description from '@/style10/Description';
import Message from '@/popup/Message';
import Footer from '@/footer'; // 底部内容
import { ApiPort } from '$ACTIONS/API';
import { useAuth } from '$UTILS/hooks/useAuth';
import { show } from '@ebay/nice-modal-react'
import useBanner from '$UTILS/hooks/useBanner';
import {checkAffQueryString} from "../actions/util";
import setupTraces from '$CONFIG/index';

const pageName = 'm1-005';
setupTraces(pageName);

const HayHar = ({data = {}}) => {
    const bannerList = useBanner({
        url: ApiPort.Banner5,
        fallbackImage: {
            desktop: '/static/images/m1-005/Web_QRP_RNG_P2P_1920x960.jpg',
            mobile: '/static/images/m1-005/Web_QRP_RNG_P2P_1920x960.jpg'
        }
    });

    const hostname = typeof window === 'undefined' ? '' : window.location.hostname;

    useEffect(() => {
        checkAffQueryString(window.location.search);
    },[])

    return (
        <div className={`style10 ${pageName}`}>
            {/* 顶部 */}
            <Header data={data} logoImage={`/static/images/logo-cn.png`}
                onBefore={() => {
                    // piwik 追踪
                    global.globalGtag('Style10_QRP', 'Login form', 'Submit_login', 'event');
                }}
                onSuccess={(result) => {
                    const text = result?.message || "登录成功";
                    show(Message, { title: '登录成功', text, buttonProps: { hidden: true } });
                    if (result.url) {
                        window.location.replace(result.url);
                    }
                }}
                onError={(error) => {
                    const text = error?.message || '登录失败';
                    show(Message, { title: '登录失败', text });
                    console.log("m1-005 login error", error)
                }}
            />
            <Banner bannerList={bannerList} />
            {/* 表单 */}
            <Central data={data} title="立即注册">
                <Description text="下载必威 APP 完成必威超级<br>任务即可获得 123 元！" iconImage={`/static/images/m1-005/iconApp.png`} />
                <RegisterForm
                    onBefore={() => {
                        // piwik 追踪
                        global.globalGtag('Style10_QRP', 'Registration form', 'Submit_registration', 'event');
                    }}
                    onSuccess={(result) => {
                        const text = result?.message || "注册成功";
                        show(Message, { title: '注册成功', text, buttonProps: { hidden: true } });
                        if (result.url) {
                            window.location.replace(result.url);
                        }
                    }}
                    onError={(error) => {
                        const text = error?.message || "注册失败";
                        show(Message, { title: "注册失败", text });
                        console.log("error", error);
                    }}
                />
            </Central>
            {/* 底部 */}
            <Footer data={data} />
        </div>
    )
}

export default HayHar;

